window.Client = {
	init: function() {
		// Do something on initialization
	},

	onRender: function(screenId) {
		// Do something when the flow renders
	}
}
window.addEventListener('heyflow-submit', (event) => {
    !function(_window, _document) {
        var OB_ADV_ID=['0022023ab18f45545bf3c29da916bed6f8'];
    if (_window.obApi) {var toArray = function(object) {return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];};_window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));return;}
    var api = _window.obApi = function() {api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);};api.version = '1.1';api.loaded = true;api.marketerId = OB_ADV_ID;api.queue = [];var tag = _document.createElement('script');tag.async = true;tag.src = '//amplify.outbrain.com/cp/obtp.js';tag.type = 'text/javascript';var script = _document.getElementsByTagName('script')[0];script.parentNode.insertBefore(tag, script);}(window, document);
    obApi('track', 'Raw Lead 1');
});
    

